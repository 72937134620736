import React, { useState } from "react";
import logo from "./logo-grups.png";
import "./App.css";
import { APPLE_STORE_APP_LINK, DEFAULT_APP_LINK, GOOGLE_PLAY_APP_LINK } from "../config/config";

const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent);
const isChromeIOS = /CriOS/i.test(navigator.userAgent);
const isSafari = isIOS && !isChromeIOS;
const appStoreLink = isIOS
  ? APPLE_STORE_APP_LINK
  : isAndroid
  ? GOOGLE_PLAY_APP_LINK
  : DEFAULT_APP_LINK;

function App() {
  const [appOpened, setAppOpened] = useState(false);

  const openAppOnClick = () => {
    // Intentar abrir la aplicación
    window.location.href = `grups-app:/${window.location.pathname}`;

    // Configurar el estado `appOpened` a `true` si el usuario sale del navegador
    setTimeout(() => {
      if (!document.hasFocus()) {
        setAppOpened(true);
      }
    }, 500);

    // Solo redirigir al App Store si la aplicación no se ha abierto
    setTimeout(() => {
      if (document.hasFocus() && !appOpened) {
        window.location.href = appStoreLink;
      }
    }, 1000);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ color: "#e06410", margin: 40 }}>
          Has recibido un link para acceder a un evento en Grups
        </p>
        <a
          className="App-link"
          onClick={(e) => {
            e.preventDefault();
            openAppOnClick();
          }}
          href="#"
        >
          Ver evento
        </a>
        
        {/* Mostrar link adicional solo en Safari */}
        {isSafari && (
          <div style={{ margin: 20 }}>
            <p>Si tienes problemas para abrir la aplicación, puedes descargarla aquí:</p>
            <a className="App-link" href={appStoreLink}>
              Descargar en {isIOS ? "App Store" : "Play Store"}
            </a>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;