import React, { useEffect } from "react";
import logo from "./logo-grups.png";
import "./App.css";
import { APPLE_STORE_APP_LINK, DEFAULT_APP_LINK, GOOGLE_PLAY_APP_LINK } from "../config/config";

const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent);

const appStoreLink = isIOS
  ? APPLE_STORE_APP_LINK
  : isAndroid
  ? GOOGLE_PLAY_APP_LINK
  : DEFAULT_APP_LINK;

function App() {
  useEffect(() => {
    // Redirige automáticamente al enlace correspondiente
    window.location.href = appStoreLink;
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ color: "#e06410", margin: 40 }}>
          Redirigiendo a la tienda de aplicaciones...
        </p>
      </header>
    </div>
  );
}

export default App;