import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EventsPage from './pages/EventsPage';
import HomePage from './pages/HomePage';
import InstallPage from './pages/InstallPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/event/*" element={<EventsPage />} />
        
        <Route path="/install" element={<InstallPage />} />

        <Route path="*" element={<HomePage />} />
      </Routes>
    </Router>
  );
};

export default App;